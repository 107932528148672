import type { SerializeFrom } from "@remix-run/node";
import pkg from "react-microsoft-clarity";

import type { loader } from "~/root";

const { clarity } = pkg;

export const HeadTags = (props: SerializeFrom<typeof loader>) => {
  if (typeof window !== "undefined") {
    clarity.init(props.config.microsoftClarity);
  }
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify({
            STAGE: props.config.sentry.stage,
            SENTRY_DSN: props.config.sentry.dsn,
          })}`,
        }}
      />

      <script async src={`https://www.googletagmanager.com/gtag/js?id=${props.config.google.gaTrackingId}`}></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `  window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', '${props.config.google.gaTrackingId}');
            `,
        }}
      />
    </>
  );
};
